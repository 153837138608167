import React, { useEffect } from "react";
import useForm from "../../hooks/use-form";
import { RoundButton, ButtonSelector } from "../form/form";
import "./uploadKV.scss";

import Spacer from "../spacer/spacer";
import FileUploader from "../fileUploader/fileUploader";
import useApi from "../../hooks/use-api";
import { useNavigate, useParams } from "@reach/router";

export default function UploadKV({ patientId }) {
  let { type } = useParams();
  let [form, setForm] = useForm({
    kvFile: null,
    type: type || "initial",
  });

  let nav = useNavigate();

  useEffect(() => {
    if (form.side === "upper") {
      setForm("unterkiefer", null);
    }
    if (form.side === "lower") {
      setForm("oberkiefer", null);
    }
  }, [form.side, setForm]);

  let { exec, status } = useApi({ endpoint: "addsteptopatient", repeat: true });

  //console.log(form);
  return (
    <div className="uploadPlan box">
      <div className="title">Kostenvoranschlag hochladen</div>
      <Spacer h={24}></Spacer>
      <ButtonSelector
        value={form.type}
        onChange={(v) => {
          setForm("type", v);
        }}
        options={[
          { label: "Erstbehandlung", id: "initial" },
          { label: "Korrektur", id: "correction" },
          { label: "Folgeschiene", id: "schiene" },
          { label: "Retainer", id: "retainer" },
        ]}
      ></ButtonSelector>

      <Spacer h={24}></Spacer>
      <FileUploader
        relType="PDF"
        value={form.kvFile}
        onChange={(d) => {
          //console.log({ d });
          setForm("kvFile", d);
        }}
        label={"Kostenvoranschlag PDF"}
      ></FileUploader>

      <Spacer h={36}></Spacer>
      <RoundButton
        status={status}
        onClick={() => {
          form.kvFile &&
            form.kvFile.id &&
            exec({
              title: "Kostenvoranschlag hochgeladen",
              type: "KV",
              pendingTask: true,
              pending: true,
              fileId: [form.kvFile.id].filter((a) => a),
              //files: form.planPDF.id,
              patientId: Number(patientId),
              ownerId: 1,
              message: "",
              dueDate: null,
              approvalDate: null,
             
              trackingInfo: "",
            }).then(() => {
              nav("/client/" + patientId);
            });
        }}
      >
        Abschicken
      </RoundButton>
    </div>
  );
}
