import { useNavigate, useParams } from "@reach/router";
import React from "react";
import { FaPaperPlane } from "react-icons/fa";
import useApi from "../../hooks/use-api";
import useForm from "../../hooks/use-form";
import {
  ButtonSelector,
  FileUpload,
  Input,
  RoundButton,
  Textarea,
} from "../form/form";
import Spacer from "../spacer/spacer";
import "./clientForm.scss";

export default function ClientForm({
  endpoint = "createpatient",
  defaults = {},
  title = "Neuen Auftrag erstellen",
  button = "Auftrag abschicken",
  hideShipping = false,
  commentOnly = false,
  defaultRedirect = false,
}) {
  //console.log(defaults);
  let { id } = useParams();
  id = parseInt(id);
  defaults = {
    ...{
      firstName: "",
      lastName: "",
      worker: "",
      type: "",
      comment: "",
      postage: null,
      cid: "",
      parentId: id || undefined,
    },
    ...defaults,
  };

  let [form, setForm] = useForm(defaults);
  let { exec, status } = useApi({ endpoint, repeat: true });
  let nav = useNavigate();

  return (
    <div className="clientForm">
      <div className="siteHeader">{title}</div>
      {!commentOnly && (
        <>
          {endpoint === "createpatient" && (
            <>
              <ButtonSelector
                value={form.type}
                onChange={(v) => {
                  setForm("type", v);
                }}
                options={[
                  { label: "Kostenvoranschlag", id: "Kostenvoranschlag" },
                  { label: "Behandlungsplan", id: "Behandlungsplan" },
                  { label: "Korrektur", id: "Korrektur" },
                  { label: "Folgeschiene", id: "Folgeschiene" },
                  { label: "Retainer", id: "Retainer" },
                ]}
              ></ButtonSelector>
              <Spacer h={24}></Spacer>
            </>
          )}
          {(form.type || endpoint !== "createpatient") && (
            <>
              <Input
                onChange={(v) => setForm("lastName", v)}
                value={form.lastName}
                label="Nachname"
              ></Input>
              <Input
                onChange={(v) => setForm("firstName", v)}
                value={form.firstName}
                label="Name"
              ></Input>
              <Input
                onChange={(v) => setForm("cid", v)}
                value={form.cid}
                label="Patientennummer"
              ></Input>
            </>
          )}
        </>
      )}
      {(form.type || endpoint !== "createpatient") && (
        <>
          <Spacer h={12}></Spacer>
          <Textarea
            value={form.comment}
            onChange={(v) => setForm("comment", v)}
            placeholder={
              "Sonderinfos wie z.B. Rückversand der Modelle und Abdrucklöffel auf Eigenkosten"
            }
            label="Kommentar"
          ></Textarea>

          {!hideShipping && (
            <>
              <ButtonSelector
                value={form.postage}
                onChange={(v) => setForm("postage", v)}
                options={[
                  { id: "upload", label: "3D Scans hochladen" },
                  { id: "post", label: "Abdruck versenden" },
                ]}
              ></ButtonSelector>
              {false && form.postage === "upload" && (
                <div className="uploadSection">
                  <FileUpload label="Oberkiefer"></FileUpload>
                  <FileUpload label="Unterkiefer"></FileUpload>
                </div>
              )}
              {false && form.postage === "post" && (
                <>
                  <Spacer h={12}></Spacer>
                  <div>
                    Wir vereinbaren eine Abholung für Sie.
                    <br />
                    Bitte halten Sie den Abdruck sicher verpackt bereit.
                  </div>
                </>
              )}
            </>
          )}
          <Spacer h={12}></Spacer>
          {(hideShipping || form.postage) && (
            <RoundButton
              status={status}
              onClick={() => {
                exec({
                  ...form,
                  initialStepTitle: form.type + "\n\nWarte auf Abdruck/Scan",
                }).then((d) => {
                  if (d.status === "OK") {
                    if (form.postage === "upload" && !defaultRedirect) {
                      nav("/uploadScans/" + d.data.patient.id);
                    } else {
                      nav("/client/" + d.data.patient.id);
                    }
                  }
                });
              }}
            >
              <FaPaperPlane></FaPaperPlane>
              <Spacer w={8}></Spacer>
              {button}
            </RoundButton>
          )}
        </>
      )}
    </div>
  );
}
