import React from "react";
import Layout from "../atoms/layout/layout";
import PraxisForm from "../atoms/praxisForm/praxisForm";

export default function NewPraxis() {
  return (
    <Layout>
      <PraxisForm></PraxisForm>
    </Layout>
  );
}
