import React from "react";
import Header from "../header/header";
import "./layout.scss";

export default function Layout({
  children,
  noMargin,
  style,
  noLogo = false,
  noNav = false,
  reload = false,
}) {
  return (
    <>
      <Header reload={reload} noLogo={noLogo} noNav={noNav}></Header>
      <div className={"inner"} style={style}>
        <div className="wrapper">{children}</div>
      </div>
    </>
  );
}
