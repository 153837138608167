import { Link } from "@reach/router";
import React, { useState } from "react";
import Calendar from "../atoms/calendar/calendar";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import useApi from "../hooks/use-api";
import useRequest from "../hooks/use-request";
import moment from "moment";
import "./dailyView.scss";
import { typesToString } from "./singleClient";
export default function DailyView() {
  return (
    <Layout>
      <div className="siteHeader">Aufmerksamkeit benötigt</div>
      <AttentionItems></AttentionItems>
      <div className="siteHeader">Anstehende Aufträge</div>
      <OutstandingTasks></OutstandingTasks>
      <Spacer h={24}></Spacer>
      <Calendar></Calendar>
    </Layout>
  );
}

export function AttentionItems() {
  let { result } = useRequest("getallpendingsteps", {}, 10);
  let data = result && result.data.steps;
  return (
    <div>
      {data &&
        data.map((d) => {
          return <AttentionItem data={d} text={<>{JSON.stringify(d)}</>} />;
        })}
    </div>
  );
}

function AttentionItem({ data }) {
  let [hideSelf, setHideSelf] = useState(false);
  let {
    type,
    title,
    id: stepId,
    owner: { company },
    patient: { cid, id: patientId, lastName },
  } = data;
  let text = JSON.stringify(data);
  if (type === "MESSAGE") {
    text =
      "Neue Nachricht von " +
      company +
      " zu Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName;
  }
  if (type === "WAIT") {
    text =
      "Neue 3D Modelle von " +
      company +
      " zu Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName;
  }
  if (type === "PLAN") {
    text =
      "Neuer Behandlungsplan zu Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName;
  }
  if (type === "KV") {
    text =
      "Neuer Kostenvoranschlag zu Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName;
  }
  if (type === "PLAN_STEP") {
    text =
      "PLAN_STEP von " +
      company +
      " zu Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName;
  }
  if (type === "PLAN_DECLINED") {
    text =
      "Behandlungsplan abgelehnt. ( " +
      company +
      " / Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName +
      " )";
  }
  if (type === "KV_DECLINED") {
    text =
      "Kostenvoranschlag abgelehnt. ( " +
      company +
      " / Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName +
      " )";
  }
  if (type === "PLAN_CORRECTION") {
    text =
      "Behandlungsplan Korrektur angefordert. ( " +
      company +
      " / Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName +
      " )";
  }

  if (type === "NEW_SCANS") {
    text =
      "Neue 3D Modelle hochgeladen. ( " +
      company +
      " / Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName +
      " )";
  }

  if (type === "PLAN_ACCEPTED") {
    text =
      "Behandlungsplan freigegeben. ( " +
      company +
      " / Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName +
      " )";
  }
  if (type === "KV_ACCEPTED") {
    text =
      "Kostenvoranschlag freigegeben. ( " +
      company +
      " / Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName +
      " )";
  }

  if (type === "AWAITING_PICKUP") {
    text =
      "Neuer Auftrag mit Abholung. ( " +
      company +
      " / Kunde  " +
      patientId +
      " / #" +
      cid +
      " / " +
      lastName +
      " )";
  }

  const { exec } = useApi({ endpoint: "updatestep", repeat: true });

  if (hideSelf) {
    return null;
  }
  return (
    <Link to={"/client/" + patientId} className="attentionItem">
      <div
        className="text"
        onClick={() => {
          //mark as read
        }}
      >
        <div>
          <b>{title}</b>
        </div>
        {text}
      </div>
      <div>
        <div
          className="actoin"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            exec({ id: stepId, pendingTask: false }).then(() => {
              setHideSelf(true);
            });
          }}
        >
          Als gelesen markieren
        </div>
        <div className="actoin">Zum Kunden springen</div>
      </div>
    </Link>
  );
}

function OutstandingTasks() {
  let { result } = useRequest(
    "getpendingplansteps",
    { filterDoneSteps: false },
    60 * 5
  );
  //console.log(result);
  let data = (result && result.data && result.data.steps) || [];
  return (
    <div className="outstandingTasks">
      {data.map((d) => {
        return <OutstandingTask data={d}></OutstandingTask>;
      })}
    </div>
  );
}
function OutstandingTask({ data }) {
  return (
    <Link to={"/step/" + data.id} className={"outstandingTask"}>
      <div className="type">
        <b>{data.patient.worker || data.worker || "Kein Bearbeiter"}</b>
      </div>
      <div className="patient">
        {new moment(data.dueDate).format("DD.MM.YYYY")}
      </div>
      <div className="step">
        {data.plan && data.plan.type && typesToString[data.plan.type]}
      </div>
      <div className="step">{data.title}</div>
      <div className="kunde">{data.patient.parent.company}</div>

      <div className="patient">
        Patient {data.patient.id} / #{data.patient.cid}
      </div>
    </Link>
  );
}
