import React from "react";
import "./TVView.scss";
import Spacer from "../atoms/spacer/spacer";
import { Link } from "@reach/router";
import useRequest from "../hooks/use-request";
import moment from "moment";
export default function TVView() {
  let { result } = useRequest(
    "getpendingplansteps",
    { filterDoneSteps: false },
    10
  );
  //console.log(result);
  let data = (result && result.data && result.data.steps) || [];
  return (
    <div style={{ background: "#444", height: "100%", display: "flex", overflowY:"auto"}}>
      <div className="taskHolder">
        {data.map((task) => {
          return <CurrentTask data={task}></CurrentTask>;
        })}
      </div>
    </div>
  );
}

function CurrentTask({ done = false, taken = false, data }) {
  return (
    <Link
      className={
        "currentTask " + (done ? "done " : " ") + (taken ? "taken " : " ")
      }
      to={"/step/" + data.id}
    >
      <div style={{ display: "flex" }}>
        <div className="clientNumber">
          #{data.patient.id}
        </div>
        <div className="clientName">{data.patient.lastName}</div>
      </div>

      <Spacer h={8}></Spacer>
      <div className="taskStep">
        {data.worker || data.patient.worker || "Kein Bearbeiter"}
      </div>
      <Spacer h={8}></Spacer>
      <div className="taskStep">{data.title}</div>
      <Spacer h={8}></Spacer>
      <div className="clientPraxis">{data.patient.parent.company}</div>
      <Spacer h={8}></Spacer>
      <div className="taskDate">Ankunft bis {new moment(data.dueDate).format("DD.MM.YYYY")}</div>
    </Link>
  );
}
