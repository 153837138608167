import { useNavigate, useParams } from "@reach/router";
import React, { useState } from "react";
import FileUploader from "../atoms/fileUploader/fileUploader";
import { RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import useApi from "../hooks/use-api";
import useForm from "../hooks/use-form";
import useUser from "../state/user";

export default function UploadScans() {
  let { client } = useParams();
  let nav = useNavigate();
  client = parseInt(client);

  let { exec, status } = useApi({ endpoint: "addsteptopatient", repeat: true });
  let [form, setForm] = useForm({
    oberkiefer: null,
    unterkiefer: null,
  });
  let { id: userId } = useUser((s) => s.user);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  return (
    <Layout>
      <div className="siteHeader">Neue Intraoral Scans hochladen</div>
      <FileUploader
        onChangeLoading={setLoading1}
        value={form["oberkiefer"]}
        onChange={(f) => setForm("oberkiefer", f)}
        label="Oberkiefer-Intraoralscan-Datei hier reinziehen oder per Klick zum Upload auswählen"
        relType="SCAN"
      ></FileUploader>
      <Spacer h={12}></Spacer>
      <FileUploader
        relType="SCAN"
        onChangeLoading={setLoading2}
        value={form["unterkiefer"]}
        onChange={(f) => setForm("unterkiefer", f)}
        label="Unterkiefer-Intraoralscan-Datei hier reinziehen oder per Klick zum Upload auswählen"
      ></FileUploader>
      <Spacer h={12}></Spacer>
      {!loading1 && !loading2 && (form.oberkiefer || form.unterkiefer) && (
        <>
          <RoundButton
            status={status}
            onClick={() => {
              exec({
                title: "3D Modelle hochgeladen",
                type: "NEW_SCANS",
                pendingTask: true,
                pending: true,
                fileId: [
                  form.oberkiefer && form.oberkiefer.id,
                  form.unterkiefer && form.unterkiefer.id,
                ].filter((a) => a),
                //files: form.planPDF.id,
                patientId: Number(client),
                ownerId: userId,
                message: "",
                dueDate: null,
                approvalDate: null,
              }).then(() => {
                nav("/client/" + client);
              });
            }}
            color="green"
          >
            Dateien hochladen
          </RoundButton>
          <Spacer h={12}></Spacer>
        </>
      )}
      <RoundButton href={"/client/" + client}>Abbrechen</RoundButton>
    </Layout>
  );
}
