import { Link } from "@reach/router";
import React from "react";
import useRequest from "../../hooks/use-request";
import { RoundButton } from "../form/form";
import Spacer from "../spacer/spacer";
import "./gkList.scss";

export default function GkList() {
  let { result, status } = useRequest("getallcustomerusersofuser", {
    role: "GROSSKUNDE",
  });
  //console.log(result);
  return (
    <div className="gkList">
      <RoundButton href="/createGk">Neuer GK</RoundButton>
      <Spacer h={12}></Spacer>
      {status === "done" &&
        result.data.customerUsers.map((gk) => {
          return <GkItem key={gk.id} data={gk}></GkItem>;
        })}
    </div>
  );
}

function GkItem({ data }) {
  return (
    <Link to={"/singleGk/" + data.id} className="box gkItem">
      <div className="gkName">{data.company}</div>
      <div className="gkPraxen">
        Praxen: {(data._count && data._count.customers) || 0}
      </div>
    </Link>
  );
}
