import create from "zustand";

import { persist } from "zustand/middleware";

const useUser = create(
  persist(
    (set) => ({
      token: null,
      user: {},
      setToken: (t) => {
        set({ token: t });
      },
      setUser: (u) => {
        set({ user: u });
      },
      logout: () => {
        set({ token: null, user: {} });
      },
    }),
    { name: "userStorage2" }
  )
);

export default useUser;
