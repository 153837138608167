import { useParams } from "@reach/router";
import moment from "moment";
import React from "react";
import { RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import useRequest from "../hooks/use-request";
import "./laufzettel.scss";

export default function Laufzettel() {
  let { id, praxis } = useParams();
  id = parseInt(id);
  praxis = parseInt(praxis);
  let { result: patient } = useRequest("getpatient", { id });
  let { result: praxisData } = useRequest("getuser", { id: praxis });
  let { result: steps } = useRequest("getstepsofpatient", { id });
  if (patient) {
    patient = patient.data.patient;
  } else {
    patient = {};
  }
  if (steps) {
    steps = steps.data.steps;
  } else {
    steps = [];
  }
  if (praxisData) {
    praxisData = praxisData.data.user;
  } else {
    praxisData = {};
  }
  console.log({ patient, steps });

  let createStep = steps.find((o) => o.type === "CREATE");
  return (
    <Layout>
      <div className="laufzettel">
        {patient && (
          <div className="printButton">
            <RoundButton
              onClick={() => {
                window.print();
              }}
            >
              Drucken
            </RoundButton>
            <Spacer h={48}></Spacer>
          </div>
        )}
        <div className="flex">
          <b>
            Patient {patient.cid} / #{id}
          </b>
          <div className="flex1"></div>
          {praxisData && praxisData.parent && praxisData.parent.company}
        </div>
        <div className="flex">
          {praxisData.company}
          <div className="flex1"></div>
          {createStep && moment(createStep.createdAt).format("DD.MM.YYYY")}
        </div>
        <div className="flex">
          {patient.firstName} {patient.lastName}
          <div className="flex1"></div>
          Fälligkeit
        </div>
        <div className="flex">
          <b>
            {createStep &&
              createStep.title.split("\n\n").length > 1 &&
              createStep.title.split("\n\n")[0]}
          </b>
          <div className="flex1"></div>
          ____________
        </div>
        <div className="flex">
          {steps
            .filter((s) => s.type === "PLAN_STEP")
            .map((step) => {
              return (
                <div className="singleStep">
                  <div className="date">
                    {moment(step.dueDate).format("DD.MM.YYYY")}
                  </div>
                  <div className="stepOps">{step.title}</div>
                </div>
              );
            })}
        </div>
        <div className="flex">
          Ausdruckdatum: {moment().format("DD.MM.YYYY")}
          <div className="flex1"></div>
          {patient.worker && <>Bearbeiter: {patient.worker}</>}
        </div>
        <div className="flex">
          {patient.comment && <>Kommentar: {patient.comment}</>}
        </div>
        <div className="printButton">
          <Spacer h={24}></Spacer>
          <RoundButton
            onClick={() => {
              window.print();
            }}
          >
            Drucken
          </RoundButton>
        </div>
      </div>
    </Layout>
  );
}
