import useUser from "../state/user";
export default function useCI() {
  let { logo, cssUrl, parent, role } = useUser((s) => s.user);
  //console.log(logo, cssUrl, parent, role);
  let defaultLogo = "/logo.svg";
  let extraCss = null;

  //console.log({ logo, color, parent });
  if (role === "GROSSKUNDE") {
    defaultLogo = logo ? logo.data : defaultLogo;
    extraCss = cssUrl ? cssUrl : null;
  }
  if (role === "ADMIN") {
    //logo = defaultLogo;
  }
  if (role === "PRAXIS") {
    //console.log({a:1, parent})
    defaultLogo = parent.logo ? parent.logo.data : defaultLogo;
    extraCss = parent.cssUrl ? parent.cssUrl : null;
  }
  //console.log({extraCss})
  return { logo: defaultLogo, extraCss };
}
