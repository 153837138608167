import { useParams } from "@reach/router";
import React from "react";
import Layout from "../atoms/layout/layout";
import useRequest from "../hooks/use-request";
import ClientForm from "../atoms/clientForm/clientForm";

export default function UpdateClient() {
  let { id } = useParams();
  id = parseInt(id);
  let { result } = useRequest("getpatient", { id });
  return (
    <Layout>
      {result && result.data && (
        <ClientForm
          endpoint={"updatepatient"}
          title={"Auftrag Aktualisieren"}
          button={"Änderungen speichern"}
          defaultRedirect
          hideShipping
          defaults={{
            ...result.data.patient,
            id: result.data.patient.id,
            Id: result.data.patient.id,
          }}
        />
      )}
    </Layout>
  );
}
