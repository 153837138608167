import axios from "axios";
import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaCheck } from "react-icons/fa";
import Loading from "react-loading";
import { baseUrl } from "../../hooks/use-api";
import useUser from "../../state/user";

import "./fileUploader.scss";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  flex: 1,
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function FileUploader({
  label,
  value,
  relType,
  onChangeLoading = () => {},
  onChange = () => {},
}) {
  let token = useUser((s) => s.token);
  let [loading, setLoading] = useState(false);
  const onDrop = (acceptedFiles) => {
    // Do something with the files
    //console.log(acceptedFiles);
    var formData = new FormData();
    formData.append("sampleFile", acceptedFiles[0]);
    formData.append("relType", relType);
    setLoading(true);
    onChangeLoading(true);
    axios
      .post(baseUrl + "upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((d) => {
        setLoading(false);
        onChangeLoading(false);
        //console.log({ file: d.data.data.file });
        onChange(d.data.data.file);
      })
      .catch(() => {
        setLoading(false);
        onChangeLoading(false);
      });
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, multiple: false });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(value ? { backgroundColor: "#5AD26F", color: "black" } : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, value]
  );

  return (
    <div className="fileUploadContainer">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {loading ? (
          <p>
            <Loading color="black" type="spin" height={20} width={20}></Loading>
          </p>
        ) : (
          <>
            {!value && <p>{label}</p>}
            {value && (
              <p>
                <FaCheck></FaCheck>
                &nbsp; Datei hochgeladen
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
}
