import { useParams } from "@reach/router";
import React, { useEffect, useState } from "react";
import Address from "../atoms/address/address";
import { RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
import PraxisList from "../atoms/praxisList/praxisList";
import Spacer from "../atoms/spacer/spacer";
import useRequest from "../hooks/use-request";
import "./singleClient.scss";

export default function SingleGk() {
  let { id } = useParams();
  id = parseInt(id);
  let { result } = useRequest("getuser", { id });
  let [gk, setGk] = useState(null);
  useEffect(() => {
    if (result && result.data) {
      setGk(result.data.user);
    }
  }, [setGk, result]);
  //console.log(result);
  return (
    <Layout>
      <div className="siteHeader">{gk && "GK: " + gk.company}</div>
      <Address data={gk}></Address>
      <Spacer h={12} />
      <div className="box" style={{ display: "flex" }}>
        <RoundButton href={"/updateGk/" + id} style={{ flex: 1 }}>
          Daten aktualisieren
        </RoundButton>
      </div>
      <PraxisList parent={id}></PraxisList>
    </Layout>
  );
}
