import { Link } from "@reach/router";
import React from "react";
import "./form.scss";
import { TwitterPicker } from "react-color";
import Spacer from "../spacer/spacer";
import ReactLoading from "react-loading";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
export function Form() {
  return <div className="form"></div>;
}

export function Input({
  label = "",
  onChange = () => {},
  onEnter = () => {},
  ...props
}) {
  return (
    <div className="input">
      <span className="label">{label}</span>
      <input
        type={"text"}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnter && onEnter();
          }
        }}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      ></input>
    </div>
  );
}

export function Textarea({
  label = "",
  onChange = () => {},
  autofocus,
  ...props
}) {
  return (
    <div className="input">
      <span className="label">{label}</span>
      <textarea
        autoFocus={autofocus}
        type={"text"}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      ></textarea>
    </div>
  );
}

export function ColorPicker({ label = "", value, onChange }) {
  return (
    <div>
      <span className="label">{label}</span>
      <Spacer h={8}></Spacer>
      <TwitterPicker
        colors={["#039498", "#f35b20", "#9900EF"]}
        color={value}
        onChange={(e) => onChange(e.hex)}
        triangle="hide"
      ></TwitterPicker>
    </div>
  );
}

export function RoundButton({
  children = "Button",
  onClick,
  href,
  active,
  color,
  style = {},
  status,
}) {
  if (color === "green") {
    style.backgroundColor = "#229922";
  }
  if (color === "red") {
    style.backgroundColor = "#ff7675";
  }
  if (color === "grey") {
    style.backgroundColor = "#eee";
  }
  const Loading = () => (
    <ReactLoading type={"spin"} color={"black"} height={20} width={20} />
  );
  if (href) {
    return (
      <Link
        style={style}
        to={href}
        className={"roundButton " + (active ? "active" : " ")}
      >
        {status === "loading" ? (
          <Loading />
        ) : status === "error" ? (
          <FaExclamationTriangle></FaExclamationTriangle>
        ) : status === "done" ? (
          <FaCheck />
        ) : (
          children
        )}
      </Link>
    );
  }
  return (
    <div
      onClick={onClick}
      className={"roundButton " + (active ? "active" : " ")}
      style={style}
    >
      {status === "loading" ? (
        <Loading />
      ) : status === "error" ? (
        <FaExclamationTriangle></FaExclamationTriangle>
      ) : status === "done" ? (
        <FaCheck />
      ) : (
        children
      )}
    </div>
  );
}

export function Selector({ options = [] }) {
  return (
    <select className="selector">
      {options.map((o) => {
        return <option key={o.key}>{o.label}</option>;
      })}
    </select>
  );
}

export function FileUpload({ label = "" }) {
  return (
    <div className="fileUpload">
      <span className="label">{label}</span>
      <input type="file"></input>
    </div>
  );
}

export function DatePicker({
  label = "",
  value = "",
  onChange = () => {},
  minDate,
}) {
  return (
    <div className="datePicker">
      <span className="label">{label} </span>
      <input
        min={minDate}
        type="date"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      ></input>
    </div>
  );
}

export function ButtonSelector({ options, value, onChange = () => {} }) {
  return (
    <div className="buttonSelector">
      {options.map((o) => {
        return (
          <RoundButton onClick={() => onChange(o.id)} active={o.id === value}>
            {o.label}
          </RoundButton>
        );
      })}
    </div>
  );
}
