import { useNavigate } from "@reach/router";
import moment from "moment";
import "moment/locale/de";
import React, { useEffect, useMemo, useState } from "react";
import useApi from "../../hooks/use-api";
import useRequest from "../../hooks/use-request";
import useUser from "../../state/user";
import { DatePicker, RoundButton } from "../form/form";
import Spacer from "../spacer/spacer";
import "./appointmentMaker.scss";
export default function AppointmentMaker({ client, plan, steps, from = 13 }) {
  console.log(from);
  let { result } = useRequest("getstep", { id: plan });

  let planJson = {};
  try {
    planJson = result?.data?.step?.plan;
  } catch (e) {}
  //console.log(planJson);

  let OKSteps = (planJson && planJson.OKSteps) || 0;
  let UKSteps = (planJson && planJson.UKSteps) || 0;
  let biggestCount = Math.max(OKSteps, UKSteps);

  let count = biggestCount;
  let spacing = 28;
  let minDate = useMemo(
    () => new moment().add(2, "weeks").format("YYYY-MM-DD"),
    []
  );
  let [startDate, setStartDate] = useState(
    new moment().add(2, "weeks").format("YYYY-MM-DD")
  );
  let [dates, setDates] = useState([]);
  let { id: userId } = useUser((s) => s.user);

  useEffect(() => {
    if (startDate) {
      let dateList = [];
      let start = moment(startDate);
      for (let i = 0; i < count; i++) {
        dateList.push(start.clone());
        start.add(spacing, "days");
      }
      setDates(dateList);
    }
  }, [startDate, count, spacing]);
  let leftUK = Number(UKSteps);
  let leftOK = Number(OKSteps);
  let currOK = 0;
  let currUK = 0;

  ////console.log({ UKSteps, OKSteps });
  let { exec } = useApi({
    endpoint: "updatestep",
    auth: true,
    repeat: true,
  });

  let { exec: assToPatient, status } = useApi({
    endpoint: "addsteptopatient",
    auth: true,
    repeat: true,
  });
  let nav = useNavigate();

  return (
    <div className="appointmentMaker">
      <>
        <DatePicker
          label="Erster Termin"
          value={startDate}
          onChange={setStartDate}
          minDate={minDate}
        ></DatePicker>
        <Spacer h={12}></Spacer>
      </>
      {dates.map((d, i) => {
        currOK++;
        currUK++;
        leftUK -= 1;
        leftOK -= 1;
        return (
          <div className="singleTermin box">
            <div className="siteHeader">Termin {i + 1 + from - 1}</div>
            <div>
              {d.locale("DE").format("ddd DD.MM.YYYY")}:<br />
            </div>
            <Spacer h={12}></Spacer>
            <div>
              <b>
                {leftOK >= 0 && <div>OK: {currOK + from - 1}</div>}
                {leftUK >= 0 && <div>UK: {currUK + from - 1}</div>}
              </b>
            </div>
          </div>
        );
      })}
      {startDate && (
        <RoundButton
          status={status}
          onClick={() => {
            let leftUK = UKSteps;
            let leftOK = OKSteps;
            let currOK = 0;
            let currUK = 0;
            assToPatient({
              title: "Behandlungsplan freigegeben",
              type: "PLAN_ACCEPTED",
              pendingTask: true,
              pending: true,
              patientId: Number(client),
              ownerId: userId,
              message: "",
              dueDate: null,
              approvalDate: null,
              planId: planJson.id,
            }).then(() => {
              exec({ id: plan, approvalDate: new Date() }).then(async () => {
                for (let i = 0; i < count; i++) {
                  currOK++;
                  currUK++;
                  leftUK -= 1;
                  leftOK -= 1;
                  let planData = {
                    plan,
                    OKSteps: leftOK >= 0 ? [currOK + from - 1] : false,

                    UKSteps: leftUK >= 0 ? [currUK + from - 1] : false,
                  };

                  await assToPatient({
                    patientId: client,
                    type: "PLAN_STEP",
                    dueDate: dates[i].toDate(),
                    message: JSON.stringify(planData),
                    trackingInfo: "",
                    planId: planJson.id,
                    title:
                      (planData.OKSteps
                        ? `OK: ${planData.OKSteps.join(" & ")}`
                        : "") +
                      (planData.UKSteps && planData.OKSteps ? ", " : "") +
                      (planData.UKSteps
                        ? `UK: ${planData.UKSteps.join(" & ")}`
                        : ""),
                  });
                }

                nav("/client/" + client, { replace: true });
              });
            });
          }}
          color="green"
        >
          Termine festlegen
        </RoundButton>
      )}
    </div>
  );
}
