import React from "react";
import "./address.scss";

export default function Address({ data }) {
  if (!data) {
    return null;
  }
  return (
    <div className="address">
      {data.email}
      <br></br>
      {data.company}
      <br></br>
      {data.street} {data.houseNumber}
      <br></br>
      {data.zipCode} {data.city}
      <br></br>
      <br></br>
      {data.telephone}
    </div>
  );
}
