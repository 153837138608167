import React from "react";
import { FaPaperPlane } from "react-icons/fa";
import { Input, RoundButton } from "../form/form";
import Spacer from "../spacer/spacer";

import useApi from "../../hooks/use-api";
import useForm from "../../hooks/use-form";
import "./praxisForm.scss";
import { useNavigate, useParams } from "@reach/router";
import useUser from "../../state/user";
export default function PraxisForm({
  endpoint = "createuser",
  defaults = {},
  title = "Neue Praxis erstellen",
  button = "Praxis erstellen",
}) {
  let params = useParams();
  defaults = {
    ...{
      email: "",
      role: "PRAXIS",
      password: "123456",
      company: "",
      street: "",
      houseNumber: "",
      zipCode: "",
      telephone: "",
      delivery: "MANUAL",
      color: "#888",
      city: "",
      parentId: params.parent ? Number(params.parent) : undefined,
    },
    ...defaults,
  };

  let { exec, status } = useApi({ endpoint });

  let nav = useNavigate();
  let [form, setForm] = useForm(defaults);
  let { user } = useUser();
  console.log(user);
  //console.log(form);
  let deleteCssUrl = {};
  if (user.role !== "ADMIN") {
    deleteCssUrl = { cssUrl: undefined };
  }
  return (
    <div className="box praxisForm">
      <div className="siteHeader">{title}</div>
      <Input
        value={form.company}
        onChange={(s) => setForm("company", s)}
        label="Name der Praxis"
      ></Input>
      {endpoint === "createuser" && (
        <Input
          value={form.email}
          onChange={(s) => setForm("email", s)}
          label="E-Mail"
        ></Input>
      )}
      <Input
        value={form.telephone}
        onChange={(s) => setForm("telephone", s)}
        label="Telefon"
      ></Input>

      <div style={{ display: "flex" }}>
        <div style={{ flex: 3 }}>
          <Input
            value={form.street}
            onChange={(s) => setForm("street", s)}
            label="Straße"
          ></Input>
        </div>
        <Spacer w={12}></Spacer>
        <div style={{ flex: 1 }}>
          <Input
            value={form.houseNumber}
            onChange={(s) => setForm("houseNumber", s)}
            label="Hausnummer"
          ></Input>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Input
            value={form.zipCode}
            onChange={(s) => setForm("zipCode", s)}
            label="PLZ"
          ></Input>
        </div>
        <Spacer w={12}></Spacer>
        <div style={{ flex: 2 }}>
          <Input
            value={form.city}
            label="Stadt"
            onChange={(s) => setForm("city", s)}
          ></Input>
        </div>
      </div>

      <Spacer h={12}></Spacer>
      <RoundButton
        status={status}
        onClick={() => {
          exec({
            ...form,
            ...deleteCssUrl,
            parentId: form.parentId ? Number(form.parentId) : undefined,
          }).then((d) => {
            if (d.status === "OK") {
              nav("/praxis/" + d.data.user.id);
            }
          });
        }}
      >
        <FaPaperPlane></FaPaperPlane>
        <Spacer w={8}></Spacer>
        {button}
      </RoundButton>
    </div>
  );
}
