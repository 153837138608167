import React from "react";
import GkForm from "../atoms/gkForm/gkForm";
import Layout from "../atoms/layout/layout";
export default function NewGk() {
  return (
    <Layout>
      <GkForm></GkForm>
    </Layout>
  );
}
