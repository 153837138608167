import React from "react";
import ClientForm from "../atoms/clientForm/clientForm";
import Layout from "../atoms/layout/layout";

export default function NewClient() {
  return (
    <Layout>
      <ClientForm></ClientForm>
    </Layout>
  );
}
