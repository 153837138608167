import React, { useEffect, useState } from "react";

import useRequest from "../hooks/use-request";
import { useParams } from "@reach/router";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import useApi from "../hooks/use-api";
import { RoundButton } from "../atoms/form/form";
import { useNavigate } from "@reach/router";

export default function ReassignPraxis() {
  let { id } = useParams();
  id = parseInt(id);
  let { result } = useRequest("getuser", { id });
  let { result: gkUsers } = useRequest("getuserbyrole", { role: "GROSSKUNDE" });
  let [praxis, setPraxis] = useState(null);
  let [selectedUser, setSelectedUser] = useState(null);

  let { exec, status } = useApi({
    endpoint: "updateuserparent",
    auth: true,
    repeat: true,
  });
  let nav = useNavigate();

  useEffect(() => {
    if (result && result.data) {
      setPraxis(result.data.user);
    }
  }, [setPraxis, result]);
  return (
    <Layout>
      <div className="siteHeader">
        {praxis && "Praxis " + praxis.company} neu zuweisen
      </div>
      <RoundButton
        onClick={() => setSelectedUser(1)}
        color={selectedUser === 1 ? "green" : ""}
      >
        Larvalis
      </RoundButton>
      <Spacer h={12}></Spacer>
      {gkUsers &&
        gkUsers.data &&
        gkUsers.data.allUsers &&
        gkUsers.data.allUsers
          .sort((a, b) => a.company.localeCompare(b.company))
          .map((gkUser) => {
            return (
              <>
                <RoundButton
                  onClick={() => setSelectedUser(gkUser.id)}
                  color={selectedUser === gkUser.id ? "green" : ""}
                >
                  {gkUser.company}
                </RoundButton>
                <Spacer h={12}></Spacer>
              </>
            );
          })}
      <Spacer h={24}></Spacer>
      {selectedUser !== null && selectedUser >= 0 && (
        <RoundButton
          status={status}
          onClick={() => {
            exec({ id, parentId: selectedUser }).then(() =>
              nav("/praxis/" + id)
            );
          }}
          color="green"
        >
          Änderungen speichern
        </RoundButton>
      )}
    </Layout>
  );
}
