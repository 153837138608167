import React from "react";
import Calendar from "../atoms/calendar/calendar";
import GkList from "../atoms/gkList/gkList";
import Layout from "../atoms/layout/layout";
import PraxisList from "../atoms/praxisList/praxisList";
import { AttentionItems } from "./dailyView";

export default function LarvalisHome() {
  return (
    <Layout reload>
      <AttentionItems></AttentionItems>
      <div className="siteHeader">Großkunden:</div>
      <GkList></GkList>
      <div className="siteHeader">Praxen:</div>
      <PraxisList></PraxisList>
      <Calendar></Calendar>
    </Layout>
  );
}
