import { useParams } from "@reach/router";
import React from "react";
import EmailChanger from "../atoms/EmailChanger/EmailChanger";
import GkForm from "../atoms/gkForm/gkForm";
import Layout from "../atoms/layout/layout";
import useRequest from "../hooks/use-request";
export default function UpdateGk() {
  let { id } = useParams();
  id = parseInt(id);
  let { result } = useRequest("getuser", { id });
  return (
    <Layout>
      {result && result.data && (
        <>
          <GkForm
            endpoint={"updateuser"}
            title={"GK Aktualisieren"}
            button={"Änderungen speichern"}
            defaults={{
              ...result.data.user,
              id: result.data.user.id,
              Id: result.data.user.id,
            }}
          ></GkForm>
          <EmailChanger id={id} value={result.data.user.email}></EmailChanger>
        </>
      )}
    </Layout>
  );
}
