import React from "react";
import ClientList from "../atoms/clientList/clientList";
import Layout from "../atoms/layout/layout";

export default function Archiv() {
  return (
    <Layout>
      <div className="siteHeader">Archiv</div>
      <ClientList showArchived noButton noCalendar></ClientList>
    </Layout>
  );
}
