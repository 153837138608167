import React, { useState } from "react";
import { FaPaperPlane } from "react-icons/fa";
import useApi from "../../hooks/use-api";
import { Input, RoundButton } from "../form/form";
import Spacer from "../spacer/spacer";
import "./EmailChanger.scss";

export default function EmailChanger({ value, id }) {
  let [email, setEmail] = useState(value);
  let { exec, status } = useApi({ endpoint: "updateemailofuser" });
  return (
    <div className="box">
      <div className="siteHeader">E-Mail aktualisieren</div>
      <div className="">
        Ein neues Passwort wird per E-Mail an den neuen Benutzer zugestellt.
      </div>
      <Spacer h={12}></Spacer>

      <div className="EmailChanger">
        <Input value={email} onChange={(m) => setEmail(m)}></Input>
        <RoundButton
          status={status}
          onClick={() => {
            exec({ id, email });
          }}
        >
          <FaPaperPlane></FaPaperPlane>
          <Spacer w={8}></Spacer>
          E-Mail ändern
        </RoundButton>
      </div>
    </div>
  );
}
