import React from "react";
import "./calendar.scss";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Link } from "@reach/router";

import deLocale from "@fullcalendar/core/locales/de";
import useRequest from "../../hooks/use-request";
import moment from "moment";
import useUser from "../../state/user";

export default function Calendar({ id }) {
  let { result } = useRequest("getpendingplansteps", {
    id: id || undefined,
  });
  //console.log(result);
  let data = (result && result.data && result.data.steps) || [];

  let { role } = useUser((s) => s.user);
  data = data.map((d) => {
    let title =
      "Patient " +
      (role === "ADMIN" ? "#" + d.patient.id : d.patient.cid) +
      "\n" +
      d.title;
    return {
      ...d,
      date: new moment(d.dueDate).format("YYYY-MM-DD"),
      title: title.split(", ").join("\n"),
      color: d.aborted ? "grey" : d.done ? "green" : "red",
      patientId: d.patient.id,
      stepId: d.id,
    };
  });
  if (role === "ADMIN") {
    let tmp = [];
    //console.log(data);
    data
      .filter((d) => !d.printed && !d.done && !d.aborted)
      .filter((d) => d.plan.type !== "RETAINER")
      .forEach((d) => {
        tmp.push({
          ...d,
          date: new moment(d.dueDate).add(-7, "days").format("YYYY-MM-DD"),
          title: "3D Druck:\n" + d.title,
          color: d.aborted ? "grey" : d.done ? "green" : "blue",
          patientId: d.patient.id,
          stepId: d.id,
          id: Math.random(),
        });
      });
    data = [...data, ...tmp];
  }
  return (
    <div className="calendar">
      <FullCalendar
        locale={deLocale}
        events={data}
        eventContent={(e) => {
          //console.log(e);
          return (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {role === "ADMIN" ? (
                <Link to={"/step/" + e.event.extendedProps.stepId}>
                  {e.event.title}
                </Link>
              ) : (
                <Link to={"/client/" + e.event.extendedProps.patientId}>
                  {e.event.title}
                </Link>
              )}
            </div>
          );
        }}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
      />
    </div>
  );
}
